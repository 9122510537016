/*
 * @Author: dongjia
 * @Date: 2021-06-25 17:25:28
 * @LastEditTime: 2021-06-25 17:46:17
 * @LastEditors: aleaner
 * @Description: 分类管理列表接口
 * @FilePath: \saas-admin-vue\src\modules\wx-store\api\product-category.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

// 分类管理列表数据
export const categoryList = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/index',
    method: 'post',
    data,
    notCancel: true
  });
};

// 更新显示状态
export const categoryUpdateShow = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/changeShow',
    method: 'post',
    data
  });
};

// 更新排序
export const UpdateSort = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/saveSort',
    method: 'post',
    data
  });
};

// 删除分类
export const delCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/delete',
    method: 'post',
    data
  });
};

// 新增分类
export const saveCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/save',
    method: 'post',
    data
  });
};

// 转移分类
export const mergeCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/move',
    method: 'post',
    data
  });
};

// 获取所有分类列表
export const allCategory = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/all',
    method: 'post',
    data
  });
};