<template>
  <el-dialog title="新增" :visible="showAddCategory" width="504px" class="dialog-vertical" @close="closeAdd">
    <el-form :model="categoryForm" size="medium" label-width="100px" :rules="form_rules" ref="categoryForm">
      <el-form-item label="名称" prop="name">
        <el-input v-model="categoryForm.name" placeholder="请填写"></el-input>
      </el-form-item>
      <el-form-item label="显示">
        <el-radio v-model="categoryForm.is_show" :label="1">显示</el-radio>
        <el-radio v-model="categoryForm.is_show" :label="0">不显示</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button size="small" type="primary" :loading="saveLoading"
        @click="handleAddCategory('categoryForm')">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { saveCategory } from "../../api/goods-category";
export default {
  name: "AddCategory",
  props: ["showAddCategory", "editObj"],
  data() {
    return {
      categoryForm: {
        is_show: 1,
        name: "",
        id: 0,
      },
      form_rules: {
        name: [{ required: true, message: "请填写分类名称", trigger: "blur" }],
      },
      saveLoading: false,
    };
  },
  watch: {
    editObj() {
      if (this.editObj.name) {
        this.categoryForm = this.editObj;
      }
    },
  },
  methods: {
    handleAddCategory(configForm) {
      this.$refs[configForm].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          saveCategory(this.categoryForm)
            .then((res) => {
              this.$emit("updateData", this.categoryForm);
              this.categoryForm = {
                is_show: 1,
                name: "",
                id: 0,
              };
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    closeAdd() {
      this.categoryForm = {
        is_show: 1,
        name: "",
        id: 0,
      };
      this.$emit("closeAddDialog");
    },
  },
};
</script>

<style scoped>
</style>
